import React from "react";
import NotificationBanner from "../Components/NotificationBanner";
import Firstpage from "../pagesfolder/firstpage";
import Secondpage from "../pagesfolder/secondpage";
import Thirdpage from "../pagesfolder/thirdpage";
import Fourthpage from "../pagesfolder/fourthpage";
import Fifthpage from "../pagesfolder/fifthpage";
import Sixthpage from "../pagesfolder/sixthpage";
import Footer from "../pagesfolder/footer";
function Home() {
  return (
    <div>
      <NotificationBanner />
      <Firstpage />
      <Secondpage />
      <Thirdpage />
      <Fourthpage />
      <Fifthpage />
      <Sixthpage />
      <Footer />
    </div>
  );
}

export default Home;
