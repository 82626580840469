import React from "react";
import { FaInstagram, FaFacebookF, FaYoutube, FaTiktok } from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="footer-logo">
          <h1>LS</h1>
          <p>LUXESKIN BY POSHMOM</p>
          <p>Glow beyond age</p>
        </div>

        <nav className="footer-nav">
          <a href="#about">About</a>
          <a href="#services">Services</a>
          <a href="#benefit">Benefit</a>
          <a href="#how-it-works">How it works</a>
          <a href="#contact">Contact</a>
        </nav>

        <div className="footer-social">
          <a
            href="https://www.tiktok.com/@luxeskinbyposhmom"
            // target="_blank"
            rel="noopener noreferrer"
          >
            <FaTiktok className="social-icon" />
          </a>
          <a
            href="https://www.instagram.com/luxeskinbyposhmom/profilecard/?igsh=MWdvNGMwOG04ZW9sNQ=="
            // target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social-icon" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61568085341063&mibextid=LQQJ4d"
            // target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF className="social-icon" />
          </a>
          <a
            href="https://www.youtube.com/@Luxeskinbyposhmom"
            // target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="social-icon" />
          </a>
        </div>
      </div>
      <div className="footer-copyright">
        <p style={{ marginTop: ".5rem" }}>
          © 2024 All rights reserved. This is Your Company
        </p>
      </div>
    </footer>
  );
};

export default Footer;
