import React from "react";
import "./secondpage.css";
import Img2 from "../assests/IMG_0178.png";

const secondpage = () => {
  return (
    <div className="container">
      <div className="image-container">
        <img src={Img2} alt="Poshmom" className="profile-image" />
      </div>
      <div className="text-container">
        <h5
          style={{ display: "inline-block", borderBottom: "2px solid black" }}
        >
          Introduction
        </h5>
        <h1>Hello, this is Poshmom!</h1>
        <p>
          Welcome to LuxeSkin by Poshmom! With over 15 years of experience
          navigating the world of skincare, I’ve personally explored countless
          products and methods, overcoming challenges and experiencing adverse
          results along the way. Now, I stand confidently in my skin, radiating
          beauty with or without makeup. My mission is to offer you a refined
          skincare program that not only combats the signs of aging but also
          enhances your natural elegance and allure. This program is designed to
          be inclusive and effective for all races and skin types.
        </p>
      </div>
    </div>
  );
};

export default secondpage;
