import React from "react";
import "./fifthpage.css";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
const FifthPage = () => {
  const cardData = [
    {
      number: "01",
      text: "Are you tired of skincare routines that fail to deliver the radiant glow you seek?",
      borderColor: "#ad5e50",
    },
    {
      number: "02",
      text: "Do you worry about the signs of aging and wonder how to combat them with elegance?",
      borderColor: "#ad5e50",
    },
    {
      number: "03",
      text: "What if you could uncover a personalised skincare routine that highlights your natural beauty?",
      borderColor: "#ad5e50",
    },
    {
      number: "04",
      text: "Ready to step confidently into a world where your skincare goals become a reality?",
      borderColor: "#ad5e50",
    },
  ];

  const faqData = [
    {
      question: "Personalised Skincare Routines",
      heading: "Reduce the Need for Invasive Procedures",
      answer: "Get routines tailored to your specific skin type and concerns.",
    },
    {
      question: "Expert Guidance on Aging",
      heading: "Reduce the Need for Invasive Procedures",
      answer: "Access expert advice to age gracefully with proven techniques.",
    },
    {
      question: "Education on Luxury Ingredients",
      heading: "Reduce the Need for Invasive Procedures",
      answer:
        "Learn about the benefits of high-quality, luxury skincare ingredients.",
    },
    {
      question: "Reduce the Need for Invasive Procedures",
      heading: "Reduce the Need for Invasive Procedures",
      answer:
        "My program emphasises preventive care, allowing you to maintain your exquisite beauty without resorting to invasive and potentially risky treatments",
    },
    {
      question: "Inclusive for All Races and Skin Types",
      heading: "Reduce the Need for Invasive Procedures",
      answer:
        "Our skincare solutions are designed to work for all skin types and tones.",
    },
    {
      question: "Exclusive Community Support",
      heading: "Reduce the Need for Invasive Procedures",
      answer:
        "Join a community of like-minded individuals for support and advice.",
    },
  ];

  const [selectedAnswer, setSelectedAnswer] = useState(faqData[0].answer);

  const [selectedheading, setSelectedheading] = useState(faqData[0].heading);

  const handleClick = (answer, heading) => {
    setSelectedAnswer(answer);
    setSelectedheading(heading);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          maxWidth: "600px",
          padding: "2rem",
          display: "block",
          textAlign: "center",
        }}
      >
        Are You Prepared to Face the Consequences of Inaction?
      </h1>
      <div className="card-container1">
        {cardData.map((card, index) => (
          <div
            key={index}
            className="card"
            style={{ borderColor: card.borderColor }}
          >
            <h2>{card.number}</h2>
            <p>{card.text}</p>
          </div>
        ))}
      </div>
      <button className="btn-primary" style={{ margin: "1rem" }}>
        <a
          href="https://luxeskinbyposhmom.kit.com/e8a1419754"
          style={{ color: "#fff" }}
        >
          Download Your Free Skincare Workbook Worth £99!
          <FaArrowRight style={{ color: "white", paddingTop: "8px" }} />
        </a>
      </button>
      <h1
        style={{
          maxWidth: "400px",
          padding: "2rem",
          paddingBottom: "0rem",
          display: "block",
          textAlign: "center",
          lineHeight: "2.5rem",
        }}
      >
        Benefits of Joining the Skincare Program
      </h1>
      <p
        style={{
          maxWidth: "400px",
          display: "block",
          textAlign: "center",
          padding: "1rem",
          lineHeight: "1.5rem",
        }}
      >
        Unlock the secrets to vibrant, glowing skin with my exclusive skincare
        program! Here’s what you’ll experience
      </p>

      <div className="faq-container">
        <div className="faq-questions">
          {faqData.map((item, index) => (
            <button
              key={index}
              className={`faq-question ${
                selectedAnswer === item.answer ? "active" : ""
              }`}
              onClick={() => handleClick(item.answer, item.heading)}
            >
              {item.question}
            </button>
          ))}
        </div>
        <div className="faq-answer">
          <h3 style={{ fontSize: "2rem", marginBottom: ".5rem" }}>
            {selectedheading}
          </h3>
          <p>{selectedAnswer}</p>
        </div>
      </div>

      <div className="info-cards-container">
        <div className="info-card" style={{ backgroundColor: "#ad5e50" }}>
          <h2>Holistic Benefits</h2>
          <p>
            Healthy, glowing skin can elevate every aspect of your life. Picture
            yourself entering a room with poise, captivating others with your
            confidence, and enjoying deeper connections in your intimate
            relationships. When you feel exquisite in your skin, it radiates in
            every interaction.
          </p>
          {/* <a href="#discover-more" className="discover-link">
            Discover More
          </a> */}
        </div>
        <div className="info-card" style={{ backgroundColor: "#ad5e50" }}>
          <h2>Consistency is Key</h2>
          <p>
            Practice Makes Permanent! Embrace consistent skincare habits for
            lasting beauty and a glow that transforms your life.
          </p>
          {/* <a
            href="#discover-more"
            className="discover-link"
            style={{ marginTop: "6rem" }}
          >
            Discover More
          </a> */}
        </div>
      </div>
      <button className="btn-primary" style={{ margin: "1rem" }}>
        <a
          href="https://luxeskinbyposhmom.kit.com/e8a1419754"
          style={{ color: "#fff" }}
        >
          Download Your Free Skincare Workbook Worth £99!
          <FaArrowRight style={{ color: "white", paddingTop: "8px" }} />
        </a>
      </button>
    </div>
  );
};

export default FifthPage;
