import React from "react";
import "./fourthpage.css";
import Fourthimg1 from "../assests/Rectangle 4646.png";
import Fourthimg2 from "../assests/Rectangle 4648.png";

const fouthpage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1
        style={{
          maxWidth: "600px",
          padding: "2rem",
          display: "block",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Take Control Today: The Best Version of Your Radiant Skin Awaits
      </h1>

      <div className="content-card">
        <div className="text-section" style={{ backgroundColor: "#ad5e50" }}>
          <h2 className="title" style={{ color: "white", fontWeight: "bold" }}>
            Time is of the Essence: Don’t delay Your Transformation!
          </h2>
          <p className="description" style={{ color: "white" }}>
            Don’t wait until it’s too late! Too many women turn to expensive
            treatments in desperation to reclaim youth, only to realize that
            true beauty begins with prevention. The sooner you start, the more
            profound your results will be
          </p>
        </div>
        <div className="image-section1">
          <img src={Fourthimg1} alt="Soap Bars" />
        </div>
      </div>
      <div className="content-card">
        <div className="image-section1">
          <img src={Fourthimg2} alt="Soap Bars" />
        </div>
        <div className="text-section" style={{ backgroundColor: "#ad5e50" }}>
          <h2 className="title" style={{ color: "white", fontWeight: "bold" }}>
            Start to Empower Yourself: Take the First Step Towards Glowing Skin
          </h2>
          <p className="description" style={{ color: "white" }}>
            Embark on your skincare journey today with my luxurious program,
            specifically tailored for you and invest in the future of your skin.
            Don’t let time pass by—take charge of your beauty today!
          </p>
        </div>
      </div>
    </div>
  );
};

export default fouthpage;
