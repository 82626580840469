import React from "react";
import "./FormModal.css"; // Custom CSS for styling
import { FaArrowRight } from "react-icons/fa";
import Modal from "react-modal";

Modal.setAppElement("#root");

function FormModal() {
  return (
    <div className="form-container">
      <button className="btn-secondary">
        <a href="https://luxeskinbyposhmom.kit.com/e8a1419754">
          Get my free Workbook
          <FaArrowRight style={{ color: "black", paddingTop: "8px" }} />
        </a>
      </button>
    </div>
  );
}

export default FormModal;
