import React from "react";
import "./thirdpage.css";
import thirdimg1 from "../assests/6E9C0DE9-77E4-43D5-857C-BC655C14FA3B.png";
import thirdimg2 from "../assests/68FFA1F8-B73D-45EE-A916-E6749C399E70.png";
import { FaArrowRight } from "react-icons/fa";

const thirdpage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1
        style={{
          maxWidth: "600px",
          padding: "2rem",
          display: "block",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Confronting the Real Challenges of Finding Your Optimal Skincare Routine
        for a Radiant Glow
      </h1>

      <div className="content-card">
        <div className="text-section">
          <span className="label">/001</span>
          <h2 className="title">Your Concerns are Valid</h2>
          <p className="description">
            I understand that the fear of aging can be overwhelming. The
            prospect of dull skin, fine lines, and loss of elasticity can impact
            your confidence. But you are not alone—there is a path to
            rejuvenation!
          </p>
        </div>
        <div className="image-section">
          <img src={thirdimg1} alt="Soap Bars" />
        </div>
      </div>
      <div className="content-card">
        <div className="image-section">
          <img src={thirdimg2} alt="Soap Bars" />
        </div>
        <div className="text-section">
          <span className="label">/002</span>
          <h2 className="title">
            Together, We’ll Navigate Your Skincare Concerns
          </h2>
          <p className="description">
            With the right expertise and support, you can achieve a radiant glow
            and gracefully defy the signs of aging. My program is meticulously
            crafted to empower you, allowing your innate beauty to shine through
            and create a lasting impression
          </p>
        </div>
      </div>
      <button className="btn-primary" style={{ margin: "1rem" }}>
        <a
          href="https://luxeskinbyposhmom.kit.com/e8a1419754"
          style={{ color: "#fff" }}
        >
          Download Your Free Skincare Workbook Worth £99!
          <FaArrowRight style={{ color: "white", paddingTop: "8px" }} />
        </a>
      </button>
    </div>
  );
};

export default thirdpage;
