import React from "react";
import "./firstpage.css";
import { FaArrowRight } from "react-icons/fa";
import FormModal from "./FormModal";
const firstpage = () => {
  return (
    <div className="promo-container">
      <div className="promo-content">
        <h1>
          Unlock Your Radiance: <br /> Elevate Your Skincare Experience!
        </h1>
        <p>
          Join the 59% of women who desire a luminous glow and even skintone!
          Discover the transformative skincare program designed to empower you
          to look and feel your best at every stage of life.
        </p>
        <div className="buttons">
          <button className="btn-primary">
            <a
              href="https://luxeskinbyposhmom.kit.com/e8a1419754"
              style={{ color: "#fff" }}
            >
              Download Your Free Skincare Workbook Worth £99!
              <FaArrowRight style={{ color: "white", paddingTop: "8px" }} />
            </a>
          </button>
          <FormModal />
        </div>
      </div>
    </div>
  );
};

export default firstpage;
