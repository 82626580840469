import React from "react";
import img1 from "../assests/Ellipse 3347.png";
import img2 from "../assests/Ellipse 3348.png";
import img3 from "../assests/Ellipse 3350.png";
import "./sixthpage.css";
import { FaInstagram, FaFacebookF, FaYoutube, FaTiktok } from "react-icons/fa";
import Img4 from "../assests/IMG_0178 (1).png";
import { FaArrowRight } from "react-icons/fa";

const sixthpage = () => {
  const faqs = [
    {
      question: "Is this program suitable for my skin type?",
      answer:
        "Absolutely! My program is tailored for anyone over 30, focusing on the unique needs of nature skin, and is inclusive for all races and skin types.",
    },
    {
      question: "How will I receive my workbook?",
      answer:
        "After you sign up, you’ll receive an email with the download link to your workbook.",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          maxWidth: "500px",
          padding: "2rem",
          paddingTop: "2rem",
          display: "block",
          textAlign: "center",
        }}
      >
        Testimonials/ Social Proof{" "}
      </h1>

      <div className="testimonials-container">
        <div className="testimonial-card">
          <img src={img1} alt="Funi O" className="profile-images" />
          <h3>Funi O</h3>
          <p>
            This program has transformed my skin and empowered me to embrace my
            age with confidence! I've never felt more radiant.
          </p>
        </div>

        <div className="testimonial-card">
          <img src={img2} alt="Emily R" className="profile-images" />
          <h3>Emily R</h3>
          <p>
            I finally found a luxurious routine that works for my mature skin.
            The guidance and support have been invaluable.
          </p>
        </div>

        <div className="testimonial-card">
          <img src={img3} alt="Funi O" className="profile-images" />
          <h3>Funi O</h3>
          <p>
            This program has transformed my skin and empowered me to embrace my
            age with confidence! I've never felt more radiant.
          </p>
        </div>
      </div>

      <div className="container">
        <div className="text-container">
          <h5
            style={{
              display: "inline-block",
              backgroundColor: "fff",
              borderRadius: "20px",
              border: "1px solid #ffa",
            }}
          >
            Who we are?
          </h5>
          <h1>Disvover More About Luxeskin by Poshmom</h1>
          <p>
            At LuxeSkin by Poshmom, I celebrate the beauty of aging with grace.
            My program is designed to empower you with effective strategies and
            support, focusing on rejuvenating and enhancing your skin’s natural
            beauty
          </p>
          <div className="social-media-icons">
            <a
              href="https://www.tiktok.com/@luxeskinbyposhmom"
              // target="_blank"
              rel="noopener noreferrer"
            >
              <FaTiktok className="icon" />
            </a>
            <a
              href="https://www.instagram.com/luxeskinbyposhmom/profilecard/?igsh=MWdvNGMwOG04ZW9sNQ=="
              // target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="icon" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61568085341063&mibextid=LQQJ4d"
              // target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF className="icon" />
            </a>
            <a
              href="https://www.youtube.com/@Luxeskinbyposhmom"
              // target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube className="icon" />
            </a>
          </div>

          <button className="btn-primary" style={{ margin: "1rem" }}>
            <a
              href="https://luxeskinbyposhmom.kit.com/e8a1419754"
              style={{ color: "#fff" }}
            >
              Download Your Free Skincare Workbook Worth £99!
              <FaArrowRight style={{ color: "white", paddingTop: "8px" }} />
            </a>
          </button>
        </div>
        <div className="image-container">
          <img src={Img4} alt="Poshmom" className="profile-image" />
        </div>
      </div>

      <h1
        style={{
          maxWidth: "520px",
          padding: "2rem",
          paddingTop: "6rem",
          display: "block",
          textAlign: "center",
          paddingBottom: "0",
        }}
      >
        Exclusive Offer: Download Your FREE Workbook Worth £99!
      </h1>
      <p
        style={{
          maxWidth: "600px",
          padding: "1rem",
          display: "block",
          textAlign: "center",
          lineHeight: "2rem",
          fontSize: "1.3rem",
          marginBottom: "1rem",
        }}
      >
        Don’t let the fear of aging dictate your beauty journey. Download your
        FREE workbook, valued at £99, now and start your journey to glowing,
        confident skin!
      </p>
      <button className="btn-primary">
        <a
          href="https://luxeskinbyposhmom.kit.com/e8a1419754"
          style={{ color: "#fff" }}
        >
          Get my free Workbook
        </a>
      </button>
      <div className="faq-container1">
        <h1
          style={{
            maxWidth: "520px",
            padding: "2rem",
            paddingTop: "6rem",
            display: "block",
            textAlign: "center",
            paddingBottom: "0",
          }}
        >
          Frequently Asked Questions
        </h1>
        {faqs.map((faq, index) => (
          <div key={index} className="faq-card1">
            <h3 className="faq-question1">{faq.question}</h3>
            <p className="faq-answer1">{faq.answer}</p>
          </div>
        ))}
      </div>
      <h1
        style={{
          maxWidth: "520px",
          padding: "2rem",
          display: "block",
          textAlign: "center",
          paddingBottom: "0",
        }}
      >
        Take the Leap: Your Glowing Transformation Begins Now!!!
      </h1>
      <p
        style={{
          maxWidth: "600px",
          padding: "1rem",
          display: "block",
          textAlign: "center",
          lineHeight: "2rem",
          fontSize: "1.3rem",
          marginBottom: "1rem",
        }}
      >
        Join a community of empowered individuals who are taking charge of their
        skincare journey. Your best, glowing skin is just a click away—embrace
        it with confidence!
      </p>

      <div
        className="faq-container1 faq-card1"
        style={{ marginBottom: "8rem" }}
      >
        <h2 style={{ alignItems: "center" }}>Disclaimer</h2>
        <p>
          The information provided on this landing page and in the associated
          skincare program is for educational purposes only and is not intended
          as medical advice. Always consult a healthcare professional before
          starting any new skincare regimen or program. <br />
          <br />
          Results Disclaimer:
          <br />
          Results may vary from person to person. While many individuals may
          experience positive changes in their skin, individual results will
          depend on various factors, including skin type, adherence to the
          program, and personal health.
          <br />
          <br />
          No Guarantees:
          <br />I do not guarantee specific results from participating in this
          program, and any testimonials shared on this page are individual
          experiences and not necessarily indicative of future results.
          <br />
          <br />
          Personal Responsibility:
          <br />
          By using this program, you acknowledge that you are responsible for
          your own skincare choices and results. The effectiveness of the
          program may vary based on individual circumstances.
        </p>
      </div>
    </div>
  );
};

export default sixthpage;
