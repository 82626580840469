import React from "react";
import "./Menu.css";
import { NavLink } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";

function Menu() {
  return (
    <>
      <div className="Navbars">
        <ul className="NavbarWrappers">
          {/* <li className="NavbarElement">
            <NavLink className="link" to="/">
              Home
            </NavLink>
          </li> */}
          <li className="NavbarElement">
            <NavLink className="link" to="/">
              About
            </NavLink>
          </li>
          <li className="NavbarElement">
            <NavLink className="link" to="/">
              Service
              <FaAngleDown className="drop" />
            </NavLink>
          </li>
          <li className="NavbarElement">
            <NavLink className="link" to="/">
              Benefit
              <FaAngleDown className="drop" />
            </NavLink>
          </li>
          <li className="NavbarElement">
            <NavLink className="link" to="/">
              Contact Us
            </NavLink>
          </li>

          <li className="Navbutton">
            <NavLink className="linkbtn" to="/">
              Sign in
              <FaArrowRight className="drop" />
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Menu;
