import React from "react";
// import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { ImCross } from "react-icons/im";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaArrowRight } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";

function Navbar({ clicked, isClicked }) {
  const handleClicked = () => {
    isClicked(!clicked);
    console.log("clicked");
  };
  return (
    <div className="Nav">
      <ul className="NavbarWrapper">
        <li className="NavLogo">Logo</li>
        <li className="NavElements">
          <a href="#about">About</a>
        </li>
        <li className="NavElements">
          <a href="#service">
            Service
            <FaAngleDown className="drop" />
          </a>
        </li>
        <li className="NavElements">
          <a href="#benefit">
            Benefit
            <FaAngleDown className="drop" />
          </a>
        </li>
        <li className="NavElements">
          <a href="#contact-us">Contact Us</a>
        </li>

        <li className="NavButton">
          <a href="#Sign-in">
            Sign in
            <FaArrowRight className="drop" />
          </a>
        </li>
      </ul>
      {!clicked ? (
        <GiHamburgerMenu onClick={handleClicked} className="Icon" />
      ) : (
        <ImCross onClick={handleClicked} className="Icon" />
      )}
    </div>
  );
}

export default Navbar;
